/**
 * Created by henian.xu on 2020/01/29.
 *
 */

table.ranking {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  border-top: 1px solid $color-main;
  background-color: $color-component-bgc;

  th,
  td {
    padding: $padding 0;
    font-weight: normal;
  }
  > tbody {
    > tr {
      > td {
        color: $color-text-minor;
        font-size: $little * 100%;
      }
      &:first-child {
        background-color: $color-main;
        > td {
          color: #fff;
        }
      }
      &:nth-child(2) {
        > td:first-child {
          color: $color-danger;
        }
      }
      &:nth-child(3) {
        > td:first-child {
          color: $color-second;
        }
      }
      &:nth-child(4) {
        > td:first-child {
          color: $color-primary;
        }
      }
    }
  }
}
