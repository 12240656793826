/**
 * Created by henian.xu on 2017/6/20.
 *
 */

$font-family: 思源黑体, PingFang, "Helvetica Neue", Helvetica, Arial, "Hiragino Sans GB", "Hiragino Sans GB W3", "Microsoft YaHei UI", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif !default;
$html-font-size: 100px !default;
$body-font-size: 30px !default;

$line-height: 1.2 !default;
