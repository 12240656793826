@charset "utf-8";
/**
 * Created by henian.xu on 2021/1/16.
 *
 */

@import "~swiper/css/swiper.css";

.swiper-pagination-bullet {
  width: $padding;
  height: $padding;
}
