@import "assets/style/_index.scss";




















































.order-goods-item {
    background-color: $color-component-bgc;
    overflow: hidden;
    padding: 0 $padding;
    > .header {
        margin: $margin 0;
        > .label {
            font-weight: bold;
        }
    }
    > .inner {
        margin: $margin 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        > .x-image {
            flex: 0 0 auto;
            margin-right: $margin-small;
        }
        > .content {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: stretch;

            > .sub-label {
                font-size: $little * 100%;
                color: $color-text-minor;
            }

            > .ctrl {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                > .sku {
                    min-width: 5em;
                    padding: $padding-small $padding;
                    background-color: $color-main;
                    border-radius: 100vw;
                    color: #fff;
                    line-height: 1;
                    text-align: center;
                    word-break: break-all;
                    white-space: pre-line;
                }
                > .price {
                    flex: 0 0 auto;
                }
            }
        }
    }
}
