@import "assets/style/_index.scss";


































































































.cell {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $padding;
    background-color: $color-component-bgc;
    min-height: $navHeight;

    > .inner {
        flex: 1;
        max-width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .extra,
        > .badge,
        > .x-icon {
            flex: 0 0 auto;
            margin-left: $margin-small;
        }

        > .icon {
            margin-right: $margin-small;
        }
        > .x-label {
            max-width: 100%;
            flex: 1 1 1%;
            //min-height: $formItemHeight;
        }

        > .extra {
            color: $color-text-minor;
        }
    }
    > .link {
        margin-left: $margin-small;
    }

    &.focus-extra {
        > .inner {
            > .x-label {
                flex: 0 0 auto;
            }

            > .extra {
                flex: 1 1 1%;
                text-align: right;
            }
        }
    }

    &.column {
        > .inner {
            flex-direction: column;
            align-items: stretch;
            > .extra {
                margin-left: 0;
                margin-top: $margin-small;
                white-space: pre-wrap;
                word-break: break-all;
            }
        }
    }
}
