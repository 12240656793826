@import "assets/style/_index.scss";






























































.category-tab-nav {
    flex: 0 0 auto;
    //min-width: 4em;
    //max-width: 6em;
    width: 160px;
    background-color: $gray2;
    overflow-scrolling: touch;
    > .item {
        padding: $padding ($formItemHeight - 1.2 * $body-font-size)/2;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        border-left: 5px solid transparent;
        min-height: 104px;

        &.active {
            color: $color-main;
            background-color: #fff;
            border-color: $color-main;
        }
    }
}
