@import "assets/style/_index.scss";



































































































































.cert-cat {
    flex: 1;
    padding: 0 $padding;
    > .out-show {
        min-height: $navHeight;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        > .label {
            flex: 0 0 auto;
        }
        > .extra {
            flex: 1;
            margin-right: $margin-small;
            text-align: right;
        }
    }
}
