@import "assets/style/_index.scss";

















































































.catalog-picker {
    flex: 1;
    min-height: $navHeight;
}
