@import "assets/style/_index.scss";


































































































































































.search-box {
    flex: 1 1 1%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;

    > .inner {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: $padding-small $padding;
        background-color: #fff;

        > .x-icon {
            flex: 0 0 auto;
            margin-right: $margin-small;
        }
        > .input {
            flex: 1 1 1%;
            > input {
                //background-color: #f00;
                padding: 0 $padding-small;
                width: 100%;
                outline: none;
                color: $color-text;
                min-height: $formItemHeight;
                &::-webkit-search-cancel-button {
                    display: none;
                }
            }
        }
        > .label {
            line-height: $formItemHeight;
        }
    }
    > .search-btn {
        flex: 0 0 auto;
        //margin-left: $margin;
    }

    &.fold {
        flex: 0 0 auto;
        > .inner {
            display: inline-flex;
            justify-content: center;
        }
    }
    &.border {
        > .inner {
            border: 1px solid $color-border;
        }
    }
    &.radius {
        border-radius: $padding-small;
        > .inner,
        > .input > input {
            border-radius: $padding-small;
        }
        > .search-btn {
            border-top-right-radius: $padding-small;
            border-bottom-right-radius: $padding-small;
        }

        &.all {
            border-radius: 100vw;
            > .inner,
            > .input > input {
                border-radius: 100vw;
            }
            > .search-btn {
                border-top-right-radius: 100vw;
                border-bottom-right-radius: 100vw;
            }
        }
        &.search-btn {
            > .inner,
            > .input > input {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
    &.padding {
        padding: $padding;
        > .inner {
            padding: 0 $padding;
        }
    }
}
