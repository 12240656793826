@import "assets/style/_index.scss";







































































































































































































































































.tab-nav {
    overflow-y: hidden;
    overflow-x: auto;
    background-color: $color-component-bgc;
    padding: 0 $padding-small;

    &::-webkit-scrollbar {
        opacity: 0;
        display: none;
    }

    > .inner {
        > .swiper-wrapper {
            width: auto;
            position: relative;
            display: inline-flex;
            min-width: 100%;
            flex-direction: row;
            justify-content: flex-start;
            align-items: stretch;

            > .item {
                flex: 0 0 auto;
                width: auto;
                height: auto;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: $padding $padding-small;
                //background-color: #fff;
                cursor: pointer;
                min-height: $navHeight;

                + .item {
                    //border-left: 1px solid $color-border;
                }

                > .inner {
                    position: relative;
                    padding: 0 $padding-small;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    > .x-icon {
                        margin-right: $margin-small;
                    }

                    > .badge {
                        position: absolute;
                        z-index: $z-index-2;
                        top: 0;
                        left: calc(100% - 0.5em);
                        transform: translateY(-50%);
                        //margin-left: $margin-small;
                    }
                    > .x-label {
                        > .label,
                        > .sub-label {
                            white-space: nowrap;
                        }
                    }
                }

                &.active {
                    color: $color-main;
                    z-index: $z-index-1;
                }
            }

            > .bar {
                position: absolute;
                left: 0;
                bottom: -1px;
                /*width: 100px;*/
                height: 5px;
                background-color: $color-main;

                &.forward {
                    transition: right 0.3s cubic-bezier(0.35, 0, 0.25, 1),
                        left 0.3s cubic-bezier(0.35, 0, 0.25, 1) 0.09s;
                }
                &.backward {
                    transition: right 0.3s cubic-bezier(0.35, 0, 0.25, 1) 0.09s,
                        left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
                }
            }
        }
    }

    &.button {
        > .inner {
            > .swiper-wrapper {
                border: none;

                > .item {
                    > .inner {
                        background-color: $gray5;
                        color: #fff;
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding: 0 $padding-big;
                        border-radius: 100vw;
                        min-width: 160px;
                    }

                    &.active {
                        > .inner {
                            background-color: $color-main;
                        }
                    }
                }

                > .bar {
                    display: none;
                }
            }
        }
    }
    &.border {
        border-top: 1px solid $color-main;
        border-bottom: 1px solid $color-main;
    }

    &.average {
        > .inner {
            > .swiper-wrapper {
                > .item {
                    flex: 1 1 1%;
                }
            }
        }
    }
}
