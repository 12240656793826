@import "assets/style/_index.scss";



































































































































.x-icon {
    position: relative;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    line-height: 1;
    vertical-align: middle;

    &.svg {
        display: inline-block;
        > .svg-inner {
            display: block;
            width: 1em;
            height: 1em;
            //vertical-align: -0.15em;
            fill: currentColor;
            overflow: hidden;
        }
    }
    > .x-image {
        width: 1em;
        height: 1em;
    }

    &.radius {
        border-radius: $padding-small;
        &.all {
            border-radius: 100vw;
        }
    }
    &.fill {
        padding: $padding;
    }

    /* 主题颜色 */
    @each $selector, $map in $themeList {
        #{$selector} {
            > .inner,
            > .svg-inner {
                color: map-get($map, 'bgc');
            }
            &.fill {
                background-color: map-get($map, 'bgc');
                > .inner,
                > .svg-inner {
                    color: map-get($map, 'color');
                }
            }
        }
    }
    /* 尺寸大小 */
    @each $key, $value in $sizeList {
        &.#{$key} {
            > .inner,
            > .svg-inner,
            > .x-image {
                font-size: $value * 100%;
            }
            &.fill {
                padding: $padding * $value;
            }
        }
    }

    > .badge {
        position: absolute;
        top: 0;
        left: calc(100% - 0.5em);
        transform: translateY(-50%);
    }
}
