@import "assets/style/_index.scss";












































































































































































































































































































































































































.x-select {
    flex: 1 1 1%;
    > .out-show {
        line-height: $formItemHeight;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .label {
            flex: 1;
        }
        > .x-icon {
            flex: 0 0 auto;
        }

        &.noop {
            color: $gray6;
        }
    }

    &.tiling {
        overflow: hidden;
        > .inner {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            /*overflow-x: auto;*/
            margin: -$padding-small;
            //margin-right: -$padding-small;

            > .item {
                padding: $padding-small;
                text-align: center;

                > .inner {
                    border: 1px solid $color-border;
                    border-radius: $padding-small;
                    padding: $padding-small;
                }

                &.active {
                    > .inner {
                        color: #fff;
                        border-color: $color-success;
                        background-color: $color-success;
                    }
                    /*&:after {
                        content: '\f017';
                        @include make-icon();
                    }*/
                }
            }
        }
    }
}
.popup.popup-select {
    > .inner {
        > .header {
            border-bottom: 1px solid $color-border;
        }
        > .body {
            padding: 0;
        }
    }
}
