@import "assets/style/_index.scss";























































































































































.campus-picker {
    background-color: $color-component-bgc;
    padding: 0 $padding;
    @include boxShadow();
    > .out-show {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        min-height: $navHeight;
        > .icon {
            flex: 0 0 auto;
        }

        > .selected {
            flex: 1;
            &.noop {
                color: $gray6;
            }
        }
        > .icon {
            margin-left: $margin-small;
        }
    }
}
