@import "assets/style/_index.scss";










































































@keyframes notification-ani {
    0% {
        transform: translateX(90vw);
    }
    100% {
        transform: translateX(-100%);
    }
}
.notification-box {
    padding: $padding;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    color: #ff9827;

    > .icon {
        flex: 0 0 auto;
        margin-right: $margin-small;
    }
    > .scroll-bar {
        flex: 1;
        overflow: hidden;

        display: inline-flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        > .inner {
            display: inline-flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            > .item {
                display: block;
                padding: 0 $padding-small;
                white-space: nowrap;
            }
        }
        // 动画
        > .inner {
            /*transform: translateX(90vw);*/
            /*animation: notification-ani 15s linear infinite;*/
        }
    }
}
