@import "assets/style/_index.scss";






















































.magic-cube {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    line-height: 0;

    > .item {
        display: inline-block;
        flex: 1 1 auto;
        > .x-image,
        > img {
            width: 100%;
            height: auto;
        }
    }
}
