@import "assets/style/_index.scss";



































































































































































































































































































































.x-video {
    position: relative;
    display: block;
    > .img-obj {
        position: relative;
        > .mask {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            > .badge {
                position: absolute;
                top: $padding;
                left: $padding;
            }
            > .x-icon {
                color: #fff;
            }
            > .label {
                margin-top: $margin;
                color: $color-main;
            }
            > .content {
                margin-top: $margin;
                color: #fff;
            }
        }
    }
    > .player {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
.player.prism-player {
    &.disabled-progress-bar {
        > .prism-controlbar {
            > .prism-progress {
                pointer-events: none;
            }
        }
    }
    &.fullscreen {
        position: fixed;
        z-index: ($z-index-7 - 10);
        left: 50%;
        top: 50%;
        width: 100vh !important;
        height: 100vw !important;
        transform: translate(-50%, -50%) rotate(90deg);
        &.is-pc {
            width: 100vw !important;
            height: 100vh !important;
            transform: translate(-50%, -50%);
        }
    }
}
