@import "assets/style/_index.scss";




























































































































































































































































































































































.attachment-list {
    margin-top: $margin-small;
    > .label {
        font-size: $little * 100%;
        //color: $color-text-minor;
    }
    > .item {
        display: block;
        padding: $padding-small;
        border: 1px solid $color-border;
        border-radius: $padding-small;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: $margin-small;
        > .label {
            flex: 1;
        }
        > .icon {
            flex: 0 0 auto;
        }
    }
}
