@import "assets/style/_index.scss";


























.linker {
    display: inline-block;
}
