@import "assets/style/_index.scss";











































































.grid {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: $padding 0;
    // margin: -1px 0 0 -1px;
    > .x-label {
        text-align: center;
        margin-top: $margin-small;
    }

    @each $key, $value in $colorList {
        &.#{$key} {
            $color-text: $color-text;
            @if (lightness($value) <71) {
                $color-text: #fff;
            }
        }
    }
}
