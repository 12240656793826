/**
 * Created by henian.xu on 2017/6/21.
 *
 */
@font-face {
    font-family: 'Fonticon';
    src: url('~assets/fonts/fonticon.ttf') format('truetype'),
    url('~assets/fonts/fonticon.woff') format('woff');
}

.f-icon {
    @include make-icon;
}

* {
    border: none;
    font-size: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
    text-rendering: geometricPrecision; //如何优化显示文本
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(255, 0, 0, 0);

    &:before,
    &:after {
        box-sizing: border-box;
    }
}

.rich-text-content{
    @include rich-text-content;
}

html, body {
    width: 100%;
    height: 100%;
}

html {
    font-family: $font-family;
    font-size: $html-font-size;
}

body {
    font-size: $body-font-size;
    line-height: $line-height;
    background-color: $color-body;
    color: $color-text;
}

a {
    text-decoration: none;
}

@each $key,$value in (h1:1.75,h2:1.5,h3:1.25,h4:1,h5:0.25,h6:0.5) {
    #{$key}{
        font-size: $body-font-size * $value;
        margin: $margin * $value 0;
    }
}

/**-- --**/


.price {
    color: $color-price;
    &:before {
        content: '￥';
        font-size: 66.666%;
        vertical-align: text-bottom;
    }
    > .old {
        font-weight: normal;
        color: $gray6;
        font-size: 66.666%;
        text-decoration: line-through;
        margin-left: .2em;
    }
}

.pointer {
    cursor: pointer;
}

.readonly,
.disabled {
    cursor: not-allowed !important;
    color: $color-text-minor;
}

.device-pc {
    .beautify-scrollbar {
        $scrollbarSize: 12px !default;

        &::-webkit-scrollbar {
            display: block;
            width: $scrollbarSize;
            height: $scrollbarSize;
            border-radius: $scrollbarSize;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: $scrollbarSize;
            background: #757575;
        }

        &::-webkit-scrollbar-button {
            display: none;
        }

        &::-webkit-scrollbar-track {
            background: #e1e1e1;
            background: var(--md-theme-demo-light-scrollbar-background-on-background-variant, #e1e1e1);
        }
    }

}

//*[lazy='loaded'],
*[lazy='loading'] {
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: 0.5rem 0.5rem !important;
}

//img[lazy='loaded'],
img[lazy='loading'] {
    display: block !important;
    margin: auto auto !important;
    height: 0.5rem !important;
    width: auto !important;
}
