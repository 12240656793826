@import "assets/style/_index.scss";













































.grid-group {
    padding: $padding;
    background-color: #fff;
    > .wrap {
        overflow: hidden;
        > .inner {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: stretch;
            flex-wrap: wrap;
            margin: -1px;
        }
    }
    &.border {
        > .wrap {
            border: 1px solid $color-border;
        }
    }
    &.item-border {
        > .wrap > .inner > .grid {
            border-style: solid;
            border-width: 1px 1px 1px 0;
            margin-top: -1px;
            border-color: $color-border;
        }
    }
}
