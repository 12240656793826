@import "assets/style/_index.scss";




































































































































































































































































































.edit-signature {
    flex: 1;
    > .out-show {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        > .x-image {
            margin-right: $margin-small;
        }
    }
    &-canvas-wrap {
        background-color: #fff;
        width: 100%;
        height: 100%;
        > .tips {
            position: absolute;
            z-index: $z-index-1;
            top: 50%;
            left: 10%;
            white-space: nowrap;
            transform: translate(-50%, -50%) rotate(90deg);
            font-weight: bold;
            font-size: 400%;
            letter-spacing: $padding-big;
            color: $gray2;
        }
        > .canvas {
            position: relative;
            z-index: $z-index-2;
            width: 100%;
            height: 100%;
        }
    }
}
.popup.popup-edit-signature {
    z-index: $z-index-7 + 10;
    &.rotate {
        width: 100vh;
        height: 100vw;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-90deg);
    }
    //transform:;
    //transform-origin: 0% 0%;
}
