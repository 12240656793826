@import "assets/style/_index.scss";









































































































































































































































.popup-wrap {
    // 只留了做弹框挂载用
    > .popup,
    > .page {
        position: absolute;
        z-index: $z-index-8;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    > .popup {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(35, 24, 21, 0.8);

        /*> .inner {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: stretch;
            background-color: #fff;

            > .header,
            > .footer {
                min-height: 100px;
                flex: 0 0 auto;
            }

            > .header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: stretch;
                line-height: 100px;
                border-bottom: 1px solid $color-border;
                color: $color-main;
                font-weight: bold;
                font-size: 32px;

                > .label {
                    flex: 1 1 1%;
                    text-align: center;
                    //color: $color-main;
                }
                > .button {
                    padding-top: 0;
                    padding-bottom: 0;
                    flex: 0 0 100px;
                    border: none;
                }
            }

            > .footer {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: stretch;
                > .button {
                    height: 100px;
                    flex: 1 1 1%;
                }
            }
            > .body {
                flex: 1 1 1%;
                overflow-x: hidden;
                overflow-y: auto;
                -webkit-overflow-scrolling: touch;
                padding: $padding;
            }
        }*/
    }
}

.popup.message-box {
    > .inner {
        position: relative;
        width: 571px;
        min-height: 495px;
        max-height: 80%;
        background-color: transparent;
        border-radius: $padding-small;
        overflow: hidden;

        > .header {
            position: relative;
            min-height: $navHeight;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            background-color: $color-component-bgc;

            > .inner {
                position: relative;
            }
        }
        > .body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: stretch;
            background-color: $color-component-bgc;

            > .inner {
                flex: 1;
                > .x-textarea,
                > .x-input {
                    flex: 0 0 auto;
                    background-color: #fff;
                    border-radius: 100vw;
                    border: 1px solid $gray8;
                    color: $color-text;
                    padding: 0 $padding-small;
                    text-align: left;
                    margin-top: $margin;
                }
                > .error {
                    margin-top: $margin;
                    color: $color-danger;
                }
            }
            > .ctrl {
                flex: 0 0 auto;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                padding: $padding;
                > .x-button {
                    min-width: 6em;
                }
            }
        }

        > .body.flex {
            > .inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: stretch;
                text-align: center;
                padding: $padding;
            }
        }
        > .close-btn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            position: absolute;
            left: 50%;
            bottom: -80px;

            margin-left: -25px;
            width: 50px;
            height: 50px;
            font-size: 26px;
            color: #fff;
            background-color: rgba(255, 255, 255, 0.5);
            border: 1px solid $color-border;
            border-radius: 100vw;
        }

        > .footer {
            //border-top: 1px solid $color-border;
            > .button {
                padding-top: 0;
                padding-bottom: 0;
                border: none;
            }
        }
    }

    &.tips {
        pointer-events: none; // 鼠标可以穿透
        background-color: rgba(0, 0, 0, 0);
        > .inner {
            width: auto;
            min-width: 50vw;
            max-width: 70vw;
            min-height: auto;
            background-color: rgba(0, 0, 0, 0.7);
            color: #fff;
            > .body {
                text-align: center;
                background-color: transparent;
            }
        }
    }
    &.loading {
        //pointer-events: none; // 鼠标可以穿透
        background-color: rgba(0, 0, 0, 0);
        > .inner {
            width: auto;
            min-width: 30vw;
            max-width: 70vw;
            min-height: 30vw;
            background-color: rgba(0, 0, 0, 0.7);
            color: #fff;
            > .body {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: $padding-big;
                > .loading-img {
                    width: 0.8rem;
                    margin: 0 auto;
                }
                > .label {
                    margin-top: $margin-big;
                }
                text-align: center;
                background-color: transparent;
            }
        }
    }
}
