/**
 * Created by henian.xu on 2017/6/21.
 *
 */
@import "animation";
@import "rankingTable";
@import "nprogress";
@import "swiper";

.cat-name-path{
  padding: $padding;
  background-color: mix(#fff,$color-main,50);
  color: mix(#000,$color-main,50);
}
.swiper-container{
  width: 100%;
}
