@import "assets/style/_index.scss";
















































































.avatar {
    &.border {
        border: $margin-small/2 solid #fff;
    }
}
