@import "assets/style/_index.scss";



































































































































































































































































.apply-item {
    display: block;
    background-color: $color-component-bgc;
    //border-radius: $padding;
    border: 1px solid $color-border;
    overflow: hidden;
    @include boxShadow();
    > .header {
        margin: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .x-icon {
            margin-right: $margin-small;
        }
        > .x-label {
            flex: 1;
            font-weight: bold;
        }
    }
    > .body {
        margin: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .inner {
            flex: 1 1 1%;
            > .cart-cat {
                margin-bottom: $margin-small;
            }
            dl {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                + dl {
                    margin-top: $margin-small;
                }
                > dt {
                    flex: 0 0 auto;
                    margin-right: $margin-small;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    @include clearfix;
                    > div {
                        width: 5em;
                        text-align: justify;
                        overflow: hidden;
                        margin-bottom: -($body-font-size * $line-height);
                        float: left;
                        &:after {
                            display: inline-block;
                            content: '';
                            padding-left: 100%;
                        }
                    }
                }
                > dd {
                    flex: 1;
                }
            }
            > .dl-wrap {
                margin-top: $margin-small;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                > dl {
                    flex: 0 0 50%;
                    + dl {
                        margin-top: 0;
                    }

                    &:nth-child(n + 3) {
                        margin-top: $margin-small;
                    }
                }
            }
        }
        > .x-icon {
            flex: 0 0 auto;
        }
    }
    > .footer {
        padding: $padding 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid $color-border;
        > .x-button {
            flex: 1;
            color: $color-main;

            + .x-button {
                border-left: 1px solid $color-border;
            }

            > .badge {
                position: absolute;
                top: $padding-small;
                right: $padding;
            }
        }
    }
}
