@import "assets/style/_index.scss";
















































































































.x-textarea,
.x-input {
    flex: 1 1 1%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    //border: 1px solid $color-border;
    > input,
    > textarea {
        flex: 1 1 1%;
        /*width: 1%;*/ // textarea 时这个会导致初始化为隐藏时第一次计算高度不正确
        line-height: $formItemHeight;
        //padding: 0 $padding-small;
        outline: none;
        resize: none;
        background-color: transparent;
        appearance: none;
        text-align: inherit;
    }
    > input {
        width: 1%;
    }

    > textarea {
        line-height: $line-height;
    }
    > .clear {
        height: $formItemHeight;
        width: $formItemHeight;
        opacity: 0.5;
    }
}
.x-input {
    > .clear {
        flex: 0 0 auto;
    }
}
.x-textarea {
    position: relative;
    padding: (($formItemHeight - $body-font-size * $line-height) / 2) 0;
    > .clear {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}
