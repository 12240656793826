@import "assets/style/_index.scss";


































































































$padding-vertical: $padding-small !default;
$padding-horizontal: $padding-big !default;
$font-size: $body-font-size !default;
.x-button {
    vertical-align: middle;
}

.x-button {
    position: relative;
    flex: 0 0 auto;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    background-color: transparent;
    border: 1px solid $color-border;
    min-height: 55px;
    line-height: 1;

    /* 尺寸大小 */
    @each $selector, $value in $sizeList_ {
        #{$selector} {
            min-height: $formItemHeight * $value;
            padding: $padding-vertical * $value $padding-horizontal * $value;
            font-size: $font-size * $value;

            &.radius {
                border-radius: $padding-vertical / 1 * $value;

                &.all {
                    border-radius: 100vw;
                }
            }
        }
    }

    &.lock-text {
        font-size: $font-size;
    }

    /* 主题颜色 */
    @each $selector, $map in $themeList {
        #{$selector} {
            background-color: map-get($map, 'bgc');
            border-color: map-get($map, 'bgc');
            color: map-get($map, 'color');

            &,
            > .x-label > .label,
            > .x-label > .sub-label {
                color: map-get($map, 'color');
            }

            &.plain {
                background-color: transparent;
                border-color: map-get($map, 'bgc');
            }

            &.plain,
            &.link {
                &,
                > .x-label > .label,
                > .x-label > .sub-label {
                    color: map-get($map, 'bgc');
                }
            }
        }
    }

    &.plain,
    &.link {
        background-color: transparent;
    }

    &.link {
        border: none;
    }

    &.fill {
        flex: 1 1 1%;
        display: flex;
    }

    &.column {
        flex-direction: column;
        text-align: center;

        > .x-icon {
            margin-right: 0;
        }
    }

    &.disabled {
        opacity: 0.5;
        background-color: $gray4;
        border-color: $gray4;

        &,
        > .x-label > .label,
        > .x-label > .sub-label {
            color: $gray6;
        }
    }
}
