@import "assets/style/_index.scss";






























.cell-group {
    .cell {
        + .cell {
            margin-top: $margin;
        }
    }
    &.border {
        .cell {
            + .cell {
                margin-top: 0;
                border-top: 1px solid $color-border;
            }
        }
    }
}
