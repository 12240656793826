@import "assets/style/_index.scss";


































.switch {
    position: relative;
    display: inline-block;
    width: $formItemHeight * 2;
    height: $formItemHeight;
    border-radius: 100vw;
    border: 1px solid $color-border;
    background-color: $gray6;
    > .dot {
        position: absolute;
        top: -1px;
        left: -1px;
        width: $formItemHeight;
        height: $formItemHeight;
        background-color: #fff;
        border: 1px solid $color-border;
        border-radius: 50%;
    }

    &.close {
        background-color: #fff;
    }
    &.open {
        background-color: $color-main;
        border-color: $color-main;
        > .dot {
            border-color: $color-main;
            left: auto;
            right: -1px;
        }
    }
}
