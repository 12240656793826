@import "assets/style/_index.scss";





































































.goods-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    background-color: $color-component-bgc;
    height: 100%;
    @include boxShadow();

    > .content {
        flex: 1;
        padding: $padding;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        > .header {
            > .goods-name {
                @include text-line(2);
            }
        }
        > .footer {
            text-align: right;
        }
    }

    &.img-right {
        flex-direction: row;
        padding: $padding;
        > .x-image {
            width: 30%;
        }
        > .content {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: stretch;
            padding: 0;
            margin-left: $margin;
            > .footer {
                text-align: left;
            }
        }
    }
}
