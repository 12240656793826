@import "assets/style/_index.scss";








































































































































































































































.container {
    flex: 1;
    position: relative;
    z-index: $z-index-1;
    overflow: hidden;

    > .back-top {
        position: absolute;
        z-index: $z-index-1;
        left: 0;
        right: 0;
        top: 0;
        text-align: center;
        padding-top: $padding-big;
        > .inner {
            display: inline-block;
            padding: $padding-small $padding-big;
            border-radius: 100vw;
            background-color: $color-main;
            color: #fff;
        }
    }

    > .scrollbar {
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        > .inner {
            //height: 100%;
            position: relative;
            //transition: margin-top 0.2s linear 0s;
            > .dropdown-refresh {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 100%;
                height: 100vh;
                background-color: $gray3;
            }
            > .content {
                //height: 100%;
                padding-top: 1px;
                margin-top: -1px;
            }
        }
    }

    &.fill {
        > .scrollbar {
            > .inner {
                &,
                > .content {
                    height: 100%;
                }
                > .content {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: stretch;
                }
            }
        }
    }
}
