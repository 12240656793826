@import "assets/style/_index.scss";













































































































.picker {
    flex: 1 1 1%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    position: relative;

    > .console {
        position: absolute;
        z-index: 1000;
        top: 0;
        left: 0;
        right: 0;
    }
}
