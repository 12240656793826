@import "assets/style/_index.scss";



























































































































.float-box {
    position: fixed;
    z-index: $z-index-6;
    right: $padding;
    bottom: $padding + $navHeight;
}
