@import "assets/style/_index.scss";




































































.course-item {
    display: block;
    padding: $padding;
    background-color: $color-component-bgc;

    > .content {
        margin-top: $margin;

        > .header {
            > .label {
                @include text-line(2);
            }
        }
        > .footer {
            margin-top: $margin-small;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            font-size: $little * 100%;
            color: $color-text-minor;
        }
    }
}
