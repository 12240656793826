@import "assets/style/_index.scss";






















































































#app {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &.device-pc {
        width: 1000px;
        margin: 0 auto;
    }
}
