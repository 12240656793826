@import "assets/style/_index.scss";


















































































































































































.region-picker {
    flex: 1;
    > .out-show {
        line-height: $formItemHeight;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        > .inner {
            flex: 1;
        }

        > .clear {
            margin: 0 $margin-small;
        }
        &.noop {
            color: $gray6;
        }
    }
}
.popup.region-picker-popup {
    > .inner {
        > .body {
            padding: 0;
            > .picker {
                > .pulley {
                    flex: 0 0 (100%/3);
                    width: (100%/3);
                }
            }
        }
    }
}
