@import "assets/style/_index.scss";



























































































































.ad-list {
    // margin: $margin 0;
    > .item {
        display: block;
        line-height: 0;
        > .x-image,
        > img {
            width: 100%;
            max-width: 100%;
            height: auto;
            line-height: 1.2;
        }
    }
}

.ad-swiper {
    .swiper-pagination {
        text-align: right;
        padding-right: $padding;
    }

    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 0;
    }

    .swiper-pagination-bullet {
        width: 0.2rem;
        height: 0.2rem;
        background: #fff;
    }

    .swiper-pagination-bullet-active {
        background: $color-main;
    }

    .swiper-slide {
        display: inline-block;
        position: relative;
        line-height: 0;
        .slide-inner {
            width: 100%;
            min-height: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            /*display: flex;
                text-align: center;*/
            > .img {
                /*position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    max-width: 100%;
                    max-height: 100%;
                    min-width: 100%;*/
                width: 100%;
                height: auto;
            }
            > .label {
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.8);
                color: #fff;
                line-height: $line-height;
                padding: $padding-small $padding;
                text-align: left;
            }
        }
    }
}
