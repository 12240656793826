@import "assets/style/_index.scss";
































































































































































.date-picker {
    flex: 1 1 1%;

    > .out-show {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        //padding: 0 $padding-small;
        line-height: $formItemHeight;
        > .label {
            flex: 1;
        }

        &.noop {
            color: $gray6;
        }
    }
}

.popup.popup-date-picker {
    z-index: $z-index-7 + 100;

    > .inner {
        > .header {
            border-bottom: 1px solid $color-border;
        }
    }
}
