@import "assets/style/_index.scss";














































































.footer-main {
    background-color: #fff;
    border-top: 1px solid $color-border;
    > .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $gray6;
        > .x-icon {
            font-size: 40px;
        }
        > .label {
            font-size: 16px;
            margin-top: $margin-small;
        }
        &.active {
            color: $gray8;
        }
    }
}
