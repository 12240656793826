@import "assets/style/_index.scss";
































































































































































$size: 50px !default;
.exam-checkbox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > .input {
        flex: 0 0 auto;
        position: relative;
        width: $size;
        height: $size;
        > .x-icon {
            font-size: $size;
        }
        > .text-icon {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border: 1px solid $color-text;
            border-radius: 100vw;
        }
        > input {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.5;
            appearance: none;
            outline: none;
        }
    }
    > label {
        flex: 1;
        margin-left: $margin-small;
        min-height: $formItemHeight;
        padding: $padding-big * 1.5 0;
    }
    &.reverse {
        > .input {
            order: 1;
        }
        > label {
            margin-left: 0;
            margin-right: $margin-small;
        }
    }
    &.disabled {
        //opacity: 0.5;
    }

    &.checked {
        > .input {
            > .text-icon {
                background-color: $color-text;
                border-color: $color-text;
                color: #fff;
            }
        }
    }
    &.error {
        > .input {
            > .text-icon {
                background-color: $color-danger;
                border-color: $color-danger;
                color: #fff;
            }
        }
    }
    &.answer-checked {
        > .input {
            > .text-icon {
                background-color: $color-primary;
                border-color: $color-primary;
                color: #fff;
            }
        }
    }
    &.correct {
        > .input {
            > .text-icon {
                background-color: $color-success;
                border-color: $color-success;
                color: #fff;
            }
        }
    }
}
