@import "assets/style/_index.scss";











































































































































































































.date {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    /*background-color: #fff;*/
}
