@import "assets/style/_index.scss";

















































.page-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: $navHeight;
    background-color: $color-main;
    > .x-label {
        flex: 1 1 1%;
        align-items: center;
    }
    > .nav-btn-group {
        flex: 0 0 auto;
        > .x-icon {
            height: $navHeight;
            min-width: $navHeight;
        }
    }
}
