/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */

$page-min-width: 1200px !default;

// 间距
$margin-small: 10px !default;
$margin: 20px !default;
$margin-big: 30px !default;

$padding-small: 10px !default;
$padding: 20px !default;
$padding-big: 30px !default;

// grid
$grid-column: 24 !default;
$grid-spacing: $margin !default;

