/**
 * Created by henian.xu on 2017/10/19 0019.
 *
 */

/* Make clicks pass-through */
.nprogress,
#nprogress {
  position: fixed;
  z-index: $z-index-8;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  //pointer-events: none; // 鼠标可以穿透
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .bar {
    background: #29d;

    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
  }

  /* Fancy blur effect */
  .peg {
    display: block;
    position: absolute;
    right: 0;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #29d, 0 0 5px #29d;
    opacity: 1.0;

    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
  }

  /* Remove these to get rid of the spinner */
  .spinner {
    position: relative;
    display: block;
    //position: fixed;
    z-index: 1031;
    top: 15px;
    right: 15px;
  }

  &.hide {
    opacity: 0 !important;
  }
}

.spinner-icon {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  //background-color: #c4d67c;
  border-radius: 50%;
  //box-shadow: -5px 5px 0px rgba(74, 89, 0, 0.2);

  //border: solid 2px transparent;
  //border-top-color: #29d;
  //border-left-color: #29d;
  //border-radius: 50%;

  //-webkit-animation: nprogress-spinner 400ms linear infinite;
  //animation: nprogress-spinner 400ms linear infinite;
  > .gif {
    width: 100px;
    height: auto;
  }
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
