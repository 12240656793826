@import "assets/style/_index.scss";























































































.article {
    background-color: $color-component-bgc;
    > .header {
        padding: $padding;
        > .label {
            margin: 0;
            text-align: center;
        }
        > .info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            margin-top: $margin;
            color: $color-text-minor;
            > span {
                + span {
                    margin-left: $margin;
                }
            }
        }
    }
    > .body {
        @include rich-text-content;

        > .content {
            &.fold {
                position: relative;
                height: 40vh;
                overflow: hidden;
            }

            > .fold {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-image: linear-gradient(
                    to top,
                    rgba(255, 255, 255, 1) 30%,
                    rgba(255, 255, 255, 0.8) 75%,
                    rgba(255, 255, 255, 0.5)
                );
                color: $color-text-minor;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                text-align: center;
                padding-bottom: $padding-big * 2;
            }
        }
    }

    > .footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        margin: $margin 0;
        color: $gray5;
        > span {
            + span {
                margin-left: $margin;
            }
        }
    }
}
