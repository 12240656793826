@import "assets/style/_index.scss";

















































































































.cms-item {
    display: block;
    padding: $padding;
    background-color: $color-component-bgc;

    > .content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        > .img-obj {
            flex: 0 0 auto;
        }
        > .info {
            flex: 1;

            > .header {
                margin-top: $margin;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                > .label {
                    flex: 1;
                    @include text-line(2);
                    font-weight: bold;
                }
            }
            > .body {
                margin-top: $margin;
            }
        }
    }
    > .footer {
        margin: $margin 0 0 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &.img-left,
    &.img-right {
        > .content {
            flex-direction: row;
            > .img-obj {
                width: 30%;
                margin-right: $margin-small;
            }
            > .info {
                > .header {
                    margin-top: 0;
                }
            }
        }
    }
    &.img-right {
        > .content {
            > .img-obj {
                order: 2;
                margin-right: 0;
                margin-left: $margin-small;
            }
            > .info {
                order: 0;
            }
        }
    }

    &.img-bottom {
        > .content {
            > .img-obj {
                order: 2;
                margin-top: $margin;
            }
            > .info {
                order: 0;
                > .header {
                    margin-top: 0;
                }
            }
        }
    }

    &.double {
        padding: 0;
        > .content {
            > .info {
                padding: 0 $padding;
            }
        }
        > .footer {
            padding: 0 $padding $padding $padding;
        }
    }
    &.img-none {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        min-height: $navHeight;
        > .content {
            > .info {
                > .header {
                    margin-top: 0;
                    > .label {
                        font-weight: normal;
                    }
                }
            }
        }
    }
}
