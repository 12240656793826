@import "assets/style/_index.scss";






























































































































































































































































.edit-study-history {
    flex: 1;
    > .out-show {
        > .inner {
            > .item {
                border: 1px solid mix(#fff, $color-main, 50);
                border-radius: $padding-small;
                padding: $padding-small;
                background-color: mix(#fff, $color-main, 80);
                color: mix(#000, $color-main, 50);

                + .item {
                    margin-top: $margin-small;
                }

                > .header {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    > .education {
                        font-weight: bold;
                    }
                    > .time {
                        font-size: $little * 100%;
                    }
                }
                > .body {
                    margin: $margin-small 0;
                }
                > .footer {
                    font-size: $little * 100%;
                }
            }
        }
        > .ctrl {
            line-height: $formItemHeight;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            > .label {
                flex: 1;
            }
        }

        &.noop {
            color: $gray6;
        }
    }
}

.edit-study-history-block {
    position: relative;
    padding: 0 $padding;
    margin: $padding 0;
    > .ctrl {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        > .x-button {
            border-bottom-left-radius: $padding;
            border-bottom-right-radius: $padding;
        }
    }
}
