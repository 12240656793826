/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */

/*z-index variables*/

$z-index-0:    -1;
$z-index-1:   100;//.page>.content

$z-index-2:   200;//验证错误

// navigation
$z-index-3:   300;//.page>.footer
$z-index-4:   400;//.page>.header

//mask
$z-index-5:   500;//.page>.components

//popup
$z-index-6:   600;//弹出菜单
$z-index-7:   700;//弹出窗
$z-index-8:   800;
$z-index-9:   900;
$z-index-10: 1000;
