@import "assets/style/_index.scss";




























































































.delivery-address-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 $padding;
    background-color: $color-component-bgc;

    > .x-icon {
        flex: 0 0 auto;
        margin: $margin $margin-small $margin 0;
    }
    > .content {
        flex: 1;
        > .header {
            margin: $margin 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            > .label {
                flex: 1;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                > .name {
                    font-weight: bold;
                }
                > .mobile {
                    color: $color-text-minor;
                    margin-left: $margin-small;
                }
            }
            > .extra {
                color: $color-text-minor;
            }
        }
        > .body {
            margin: $margin 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            > .inner {
                flex: 1;
            }
        }
        > .footer {
            margin: $margin 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            > .x-button {
                + .x-button {
                    margin-left: $margin-small;
                }
            }
        }
    }
}
