@import "assets/style/_index.scss";








































































































































































































.category-tab {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    > .content {
        position: relative;
        height: 100%;
        flex: 1 1 1%;
        background-color: #fff;
        .category-tab-panel-header {
            > .inner {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                height: $formItemHeight + $padding-small * 2;
                padding: 0 $padding-small;
                background-color: rgba(246, 249, 252, 0.9);
                border-top: 1px solid $color-border;
                border-bottom: 1px solid $color-border;
                > .label {
                }
            }
            &.float {
                position: absolute;
                z-index: $z-index-2;
                top: 0;
                left: 0;
                right: 0;
            }
        }
        > .scroll {
            position: relative;
            z-index: $z-index-1;
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            overflow-scrolling: touch;
        }
    }
}
