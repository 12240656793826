@import "assets/style/_index.scss";











































































.address-picker {
    flex: 1;
    min-height: $formItemHeight;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    > .out-show {
        flex: 1;
        > .noop {
            color: $gray6;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            > .x-icon {
                margin-right: $margin-small;
            }
        }
    }
    > .x-icon {
        margin-left: $margin-small;
    }
}
