@import "assets/style/_index.scss";




































































































.panel {
    > .header {
        padding: 0 $padding;
        background-color: #fff;

        > .inner {
            padding: $padding 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            //border-bottom: 1px solid $color-border;

            > .extra,
            > .badge,
            > .x-icon {
                flex: 0 0 auto;
                margin-left: $margin-small;
            }

            > .x-icon:not(.link) {
                margin-left: 0;
                margin-right: $margin-small;
            }

            > .x-icon,
            > .x-label {
                vertical-align: middle;
            }

            > .x-label {
                flex: 1 1 1%;
                //min-height: $formItemHeight;
            }

            > .extra {
                color: $gray6;
            }

            > .fold-icon {
                background-color: $color-main;
                color: #fff;
                padding: $padding-small;
                font-size: 50%;
                border-radius: 100vw;
            }
        }

        &.fold {
            > .inner {
                border-bottom: none;
            }
        }
    }
    > .body {
        background-color: #fff;
        overflow: hidden;
        //padding: $padding;
    }
}
