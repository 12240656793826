@import "assets/style/_index.scss";





























































































































































































































































.form-item {
    padding: $padding;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    background-color: #fff;

    > .header {
        order: 0;
        flex: 0 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        min-height: $formItemHeight;
        max-width: 50%;
        &,
        > .x-icon {
            flex: 0 0 auto;
            margin-right: $margin-small;
        }
        > .x-label {
            flex: 1 1 1%;
            > .label,
            > .sub-label {
                @include text-line(1);
            }
        }
    }
    > .body {
        min-height: $formItemHeight;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        order: 1;
        flex: 1 1 1%;
        word-break: break-all;
        // line-height: $formItemHeight;
    }
    > .extra {
        width: 100%;
        order: 3;
        align-self: center;
    }
    > .feedback {
        order: 2;
        flex: 0 0 auto;
        min-height: $formItemHeight;
        margin-left: $margin-small;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        // 明文显示提示
        &.show {
            order: 4;
            width: 100%;
            display: block;
            min-height: auto;
            margin-left: 0;
        }
        > .x-icon {
            vertical-align: inherit;
        }
    }

    &.column {
        > .header {
            flex: 1 1 1%;
            max-width: none;
            margin-right: 0;
        }
        > .body {
            flex: 0 0 auto;
            width: 100%;
        }
        > .extra {
            order: 0;
        }
        > .feedback {
            order: 0;
            &.show {
                order: 2;
            }
        }
    }
    &.right:not(.column) {
        > .body {
            &,
            > .x-select.tiling > .inner {
                justify-content: flex-end;
            }
            text-align: right;
        }
    }
}
