/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */

// 清除浮动
@mixin clearfix() {
  &:before,
  &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
}

// 创建图标
@mixin make-icon {
  display: inline-block;
  font: normal normal normal 14px/1 Fonticon;
  font-size: inherit;
  line-height: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// 单选或多行(chrome)显示
@mixin text-line($line) {
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin rich-text-content {
  white-space: initial;
  word-break: break-all;
  line-height: 1.5;
  img,
  video {
    max-width: 100%;
    height: auto;
  }
}

@mixin setScrollAxis($axis) {
  @if ($axis=='y') {
    overflow-x: hidden;
    overflow-y: auto;
  } @else if ($axis=='x') {
    overflow-x: auto;
    overflow-y: hidden;
  } @else {
    overflow-x: auto;
    overflow-y: auto;
  }
  -webkit-overflow-scrolling: touch;
}

@mixin boxShadow{
  box-shadow: 0 10px 10px -5px $gray4;
}

@mixin bodyDl($width){
  > dl {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    + dl {
      margin-top: $margin;
    }
    > dt {
      flex: 0 0 auto;
      margin-right: $margin-small;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      @include clearfix;
      > div {
        width: $width;
        text-align: justify;
        overflow: hidden;
        margin-bottom: -($body-font-size * $line-height);
        float: left;
        &:after {
          display: inline-block;
          content: '';
          padding-left: 100%;
        }
      }
      &:after {
        content: ':';
        //margin-right: $margin-small;
      }
    }
    > dd {
      flex: 1;
    }
  }
}
