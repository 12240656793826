@import "assets/style/_index.scss";
























































































































































































$baseHeight: $formItemHeight;
.input-number {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: $baseHeight;
    border: 1px solid $color-border;

    > button {
        appearance: none;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: $baseHeight;
        width: $baseHeight;
        text-align: center;
        color: $color-main;
        outline: none;
        font-size: 26px;
        &.disabled {
            //border-color: $gray5;
            color: $gray5;
        }
        &.plus {
            //margin-left: 5px;
            /*background-color: $color-main;
            color: #fff;
            &.disabled {
                background-color: $gray5;
                border-color: $gray5;
            }*/
        }
    }
    > .input,
    > input {
        appearance: none;
        font-size: 30px;
        line-height: $baseHeight;
        outline: none;
        padding: 0 $padding-small;
        text-align: center;
        border-left: 1px solid $color-border;
        border-right: 1px solid $color-border;
    }
    > .input {
        display: block;
        min-width: 1em;
    }
    > input {
        appearance: none;
        width: 4em;
        &:disabled {
            background-color: transparent;
            color: $gray6;
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            appearance: none;
        }
    }
}
