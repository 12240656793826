@import "assets/style/_index.scss";












































































































































.student-item {
    display: block;
    background-color: $color-component-bgc;
    //border-radius: $padding;
    border: 1px solid $color-border;
    overflow: hidden;
    @include boxShadow();

    > .header {
        margin: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .x-icon {
            margin-right: $margin-small;
        }
        > .x-label {
            flex: 1;
            font-weight: bold;
        }
    }
    > .body {
        margin: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .inner {
            flex: 1;
            @include bodyDl(4em);
        }
    }
    > .footer {
        margin: $padding 0;
        text-align: right;
    }
}
