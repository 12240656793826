@import "assets/style/_index.scss";

























































































.tab {
    &.average {
        flex: 1;
    }
}
