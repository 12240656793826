@import "assets/style/_index.scss";





















































.experience-list {
    > .item {
        padding: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        + .item {
            border-top: 1px solid $color-border;
        }

        > .label {
            flex: 1;
        }

        &.noop {
            > .label {
                text-align: center;
                color: $gray6;
            }
        }
    }
}
