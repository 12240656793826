@import "assets/style/_index.scss";




























































































































.console {
    position: fixed;
    z-index: $z-index-10;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: $padding;
}
