@import "assets/style/_index.scss";










































.badge {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    //background-color: $color-danger;
    //color: #fff;
    border-radius: 100vw;
    line-height: 1.5;
    min-width: 1.5em;
    padding: 0 $padding-small;

    &.dot {
        min-width: auto;
        width: 20px;
        height: 20px;
        padding: 0;
    }

    @each $key, $value in $colorList {
        &.#{$key} {
            $color-text: $color-text;
            @if (lightness($value) <71) {
                $color-text: #fff;
            }
            background-color: $value;
            color: $color-text;
            &.plain {
                background-color: transparent;
                border: 1px solid $value;
                color: $value;
            }
        }
    }
    /* 尺寸大小 */
    @each $key, $value in $sizeList {
        &.#{$key} {
            font-size: $value * 100%;
        }
    }
}
