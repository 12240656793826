@import "assets/style/_index.scss";


































































































































































.x-image {
    overflow: hidden;
    display: inline-block;
    vertical-align: top; // 为解决 inline-block 下多余的空白

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: fill;
    }

    &.ratio {
        display: block;

        > .inner {
            position: relative;
            height: 0;

            > img {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }
        }
    }

    &.radius {
        border-radius: $padding-small;

        &.all {
            border-radius: 100%;
        }
    }

    &.block {
        display: block;
    }
}
