@import "assets/style/_index.scss";

























































































































.cert-cat-picker {
    flex: 1;
}
