@import "assets/style/_index.scss";





































































































































































.popup-wrap {
    // .popup-wrap 只留了做弹框挂载用

    > .page,
    > .popup {
        position: absolute;
        z-index: $z-index-7;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    > .popup {
        &:not(.page) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: rgba(35, 24, 21, 0.8);
            overflow: hidden;
        }

        > .inner {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: stretch;
            background-color: #fff;
            max-height: 90%;
            > .header {
                flex: 0 0 auto;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                min-height: $navHeight;
                border-bottom: 1px solid $color-border;

                > .x-label {
                    flex: 1 1 1%;
                    text-align: center;
                }
            }
            > .body {
                flex: 1 1 auto;
                padding: $padding;
                overflow-x: hidden;
                overflow-y: auto;
                -webkit-overflow-scrolling: touch;
            }
            > .footer {
                flex: 0 0 auto;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: stretch;
                min-height: $navHeight;
                > *,
                > .x-button {
                    flex: 1 1 1%;
                }
            }
        }

        &.up {
            justify-content: flex-start;
            align-items: stretch;
        }
        &.down {
            justify-content: flex-end;
            align-items: stretch;
        }
    }
}
