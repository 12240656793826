@import "assets/style/_index.scss";































































































































































































































































//$itemHeight: 30px;
$itemHeight: $formItemHeight;
.pulley {
    flex: 1 1 1%;
    position: relative;
    min-height: 400px;
    overflow: hidden;
    > .console {
        position: relative;
        z-index: 1000;
    }
    > .mask {
        position: absolute;
        z-index: 200;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.6)),
            linear-gradient(to top, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.6));
        background-repeat: no-repeat;
        background-position: top, bottom;
        background-size: 100% calc(50% - #{$itemHeight/2});
    }
    > .indicator {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        height: $itemHeight;
        border: solid $color-border;
        border-width: 1px 0;
        background-color: $gray1;
    }
    > .content {
        position: absolute;
        z-index: 100;
        top: calc(50% - #{$itemHeight/2});
        left: 0;
        right: 0;
        > .item {
            text-align: center;
            line-height: $itemHeight;
            //@include text-line(1);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
