@import "assets/style/_index.scss";







































































































































.form {
    .form-item {
        + .form-item {
            margin-top: $margin;
        }
    }
    &.border {
        .form-item {
            + .form-item {
                margin-top: 0;
                border-top: 1px solid $color-border;
            }
        }
    }
}
