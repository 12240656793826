@import "assets/style/_index.scss";























































































































































.pagination {
    padding: $padding;

    .pagination-semi,
    .pagination-loading,
    .pagination-complete,
    .pagination-nothing {
        text-align: center;
    }

    &:not(.auto) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
        > .x-button {
            margin-left: -1px;
            flex: 1 1 1%;
            padding-left: 0;
            padding-right: 0;
            min-height: $formItemHeight;
        }
    }
}
