@import "assets/style/_index.scss";










































































































































































































































































.quick-navigation {
    > .inner {
        position: absolute;
        z-index: $z-index-2;
        right: 0;
        bottom: $padding + $navHeight;
        //top: 80%;
        transform: translateX(100%);
        transition-duration: 0.5s;

        > .header {
            position: absolute;
            right: 100%;
            top: 50%;
            transform: translateY(-50%);
            white-space: nowrap;
            line-height: 1.2;
            padding: $padding-small;
            border-top-left-radius: $padding-small/2;
            border-bottom-left-radius: $padding-small/2;
            background-color: $color-main;
            color: #fff;
            font-size: $little * 100%;
        }
        > .body {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: $padding-small;
            background-color: $gray8;
            color: $gray2;
            border-top-left-radius: $padding-small/2;
            border-bottom-left-radius: $padding-small/2;

            > .item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: $padding-small;
                min-width: 100px;
                > .label {
                    font-size: $little * 100%;
                    margin-top: $margin-small;
                    line-height: 1;
                }
            }
        }

        &.move {
            transition-duration: 0s;
        }
        &.open {
            transform: translateX(0);
        }
    }
    > .quick-navigation-popup {
        position: absolute;
        z-index: $z-index-1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.5);
    }
}
