@import "assets/style/_index.scss";

















































































.pages,
.page {
    position: relative;
    flex: 1 1 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    background-color: $color-body;
}
.pages {
    overflow: hidden;
}
.page {
    background-color: $color-page;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
