@import "assets/style/_index.scss";







































































































































































































































































































































































































































































































































































































































































































































































.upload {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: $padding/2;
    /*background-color: #fff;*/

    > .item {
        flex: 0 0 auto;
        width: 100%;
        padding: $padding/2;

        > .inner {
            box-shadow: 0 0.05rem 0.1rem 0 rgba(0, 0, 0, 0.3);

            position: relative;
            border-radius: $padding-small;

            > .del {
                position: absolute;
                z-index: 300;
                top: -0.15rem;
                right: -0.15rem;
                color: $color-danger;
                font-size: 0.4rem;
                line-height: 0.4rem;
                width: 0.4rem;
                text-align: center;
                border-radius: 50%;
                background-color: #fff;
            }

            > .progress {
                position: absolute;
                z-index: 100;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border-radius: 0.1rem;
                background: rgba(0, 0, 0, 0.5);

                > .inner {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 0.8rem;
                    height: 0.8rem;
                    margin-top: -0.4rem;
                    margin-left: -0.4rem;
                    box-sizing: border-box;
                    border: solid 5px transparent;
                    border-top-color: $color-main;
                    border-left-color: $color-main;
                    border-radius: 50%;

                    animation: nprogress-spinner 400ms linear infinite;
                }

                > .label {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    font-size: 0.26rem;
                    color: #fff;
                }
            }

            > .error {
                position: absolute;
                z-index: 200;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border-radius: 0.1rem;
                background: rgba(0, 0, 0, 0.5);
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                color: #fff;
            }
        }

        &.add {
            > .inner {
                position: relative;
                box-shadow: none;
                display: block;
                background: $gray2 center no-repeat;
                background-size: 100% 100%;

                > .icon-wrap {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    color: $gray6;
                    > .inner {
                        padding: $padding-big;
                        border-radius: $padding-small;
                        background-color: rgba(233, 236, 239, 0.7);
                        > .x-icon {
                            vertical-align: middle;
                            //font-size: 20px;
                        }
                        > .label {
                            margin-top: $margin-small;
                        }
                    }
                }
            }

            &.hide {
                position: absolute;
                top: -200vh;
                left: -200vw;
            }
        }
    }
}
.edit-img-wrap {
    height: 100%;
    /* Ensure the size of the image fit the container perfectly */
    > img {
        display: block;
        margin: 0 auto;

        /* This rule is very important, please don't ignore this */
        max-width: 100%;
        max-height: 100%;
    }
}
