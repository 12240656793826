@import "assets/style/_index.scss";































































































































































































.answer-card {
    > .out-show {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        line-height: 1;
        > .x-icon {
            margin-right: $margin-small;
        }
    }
}
.popup.popup-answer-card {
    > .inner {
        > .body {
            padding: $padding-small;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            > .item {
                flex: 0 0 auto;
                width: (100%/5);
                padding: $padding-small;

                > .inner {
                    width: 100%;
                    padding-top: 100%;
                    height: 0;
                    position: relative;
                    background-color: $gray3;
                    border-radius: $padding;
                    > .content {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;

                        > .answer {
                            position: absolute;
                            z-index: $z-index-1;
                            top: 0;
                            right: 0;
                            width: 100%;
                            white-space: normal;
                            word-break: break-all;
                            padding: 0 $padding-small;
                            border-radius: $padding;
                            background-color: rgba(0, 0, 0, 0.2);
                            color: #fff;
                            opacity: 0.2;
                        }
                        > .index {
                            position: relative;
                            z-index: $z-index-2;
                        }
                    }
                }

                &.correct {
                    > .inner {
                        background-color: mix(#fff, $color-success, 50%);
                        color: #fff;
                    }
                }
                &.error {
                    > .inner {
                        background-color: mix(#fff, $color-danger, 50%);
                        color: #fff;
                    }
                }
                &.active {
                    > .inner {
                        background-color: mix(#fff, $color-primary, 50%);
                        color: #fff;
                    }
                }
            }
        }
    }
}
