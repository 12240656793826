@import "assets/style/_index.scss";
































.comment-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    > .avatar {
        flex: 0 0 auto;
        margin-right: $margin-small;
    }
    > .content {
        flex: 1;
        > .header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            > .nickname {
                font-weight: bold;
            }

            > .agree {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                line-height: 1;
            }
        }
    }
}
