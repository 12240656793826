@import "assets/style/_index.scss";










































































































































































































.image-list {
    flex: 1;
    position: relative;

    > .quantity-info {
        position: absolute;
        z-index: $z-index-2;
        left: 0;
        top: 0;
        padding: 0 $padding 0 $padding-small;
        background-color: rgba(255, 255, 255, 0.5);
        border-bottom-right-radius: $padding;
        border: solid $color-border;
        border-width: 0 1px 1px 0;
        font-size: $little * 100%;
        line-height: 1.5;
        color: $gray6;
    }

    > .inner {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: wrap;
        > .item {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            > .x-image,
            > .x-video {
                display: block;
                width: 100%;
            }

            > .label {
                align-self: stretch;
                padding: $padding-small $padding;
            }

            > .delete {
                position: absolute;
                z-index: $z-index-2;
                top: 0;
                right: 0;
                margin-top: -0.2rem;
                margin-right: -0.2rem;
            }
        }
    }

    &.padding {
        > .inner {
            margin: -$padding-small;
            > .item {
                padding: $padding-small;
                > .delete {
                    top: $padding-small;
                    right: $padding-small;
                }
            }
        }
    }

    &.auto-width {
        > .inner {
            > .item {
                > .x-image,
                > .x-video {
                    width: initial;
                    max-width: 100%;
                }
            }
        }
    }
}
