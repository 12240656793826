@import "assets/style/_index.scss";



















































































































































































































.topic-list {
    &.swiper-container {
        flex: 1 1 1%;
        //height: 100%;
        background-color: $color-component-bgc;
        > .swiper-wrapper {
            > .swiper-slide {
                @include setScrollAxis('y');
            }
        }
    }
}
