@import "assets/style/_index.scss";




































































.popup.dialog {
    > .inner {
        position: relative;
        width: 80%;
        max-height: 80%;
        background-color: transparent;

        > .header {
            order: 0;
            border-bottom: 1px solid $color-border;
            background-color: $color-component-bgc;
            border-top-left-radius: $padding-small;
            border-top-right-radius: $padding-small;
            overflow: hidden;

            ~ .body {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
        > .body {
            order: 1;
            background-color: $color-component-bgc;
            border-radius: $padding-small;
            padding: 0;
        }
        > .footer {
            order: 2;
            border-bottom-left-radius: $padding-small;
            border-bottom-right-radius: $padding-small;
            overflow: hidden;
            ~ .body {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
        > .close-btn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            position: absolute;
            left: 50%;
            bottom: -80px;

            margin-left: -25px;
            width: 50px;
            height: 50px;
            font-size: 26px;
            color: #fff;
            background-color: rgba(255, 255, 255, 0.5);
            border: 1px solid $color-border;
            border-radius: 100vw;
        }
    }
}
