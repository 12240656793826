@import "assets/style/_index.scss";


































































.toolbar {
    .icon {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 1rem;
        height: 1rem;
    }
    .label {
        min-height: $body-font-size * 1.5;
        @include text-line(1);
    }
}
