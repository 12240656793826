@import "assets/style/_index.scss";















































































.x-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;

    > .label {
        order: 0;
    }
    > .sub-label {
        order: 1;
        font-size: $little * 100%;
        // color: $gray6;
        &,
        ~ .label {
            //line-height: 1.2;
        }
    }

    /* 主题颜色 */
    @each $key, $value in $colorList {
        &.#{$key} {
            > .label {
                color: $value;
            }
            > .sub-label {
                color: mix($value, #fff, 50%);
            }
        }
    }
    /* 尺寸大小 */
    @each $key, $value in $sizeList {
        &.#{$key} {
            > .label {
                font-size: $value * 100%;
            }
            > .sub-label {
                font-size: $value * 100% * $little;
            }
        }
    }
}
