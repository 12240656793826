/**
 * Created by henian.xu on 2017/6/20.
 *
 */

/*灰色系*/
$gray1: #f8f9fa !default;
$gray2: #e9ecef !default;
$gray3: #dee2e6 !default;
$gray4: #ced4da !default;
$gray5: #bdc9d8 !default; // 设计指定的
$gray6: #868e96 !default;
$gray7: #495057 !default;
$gray8: #252e39 !default; // 设计指定的
$gray9: #212529 !default;

/*主色系*/
$color-main: #4dcb73 !default; // 设计指定的
$color-second: #ff6d3b !default; // 设计指定的
$color-body: $gray8 !default;
$color-page: $gray1 !default;
$color-component-bgc: #fff !default;

/*辅助色*/
$color-primary: #31bbef !default; // 设计指定的
$color-success: #67c23a !default;
$color-warning: #f8d03b !default;  // 设计指定的--
$color-danger: #fe1d64 !default; // 设计指定的
$color-info: #909399 !default;

//$color-red: #f00 !default;
//$color-green: #04be02 !default;
//$color-blue: #49a9ee !default;
//$color-yellow: #ffcc33 !default;

$color-price: #ff5000 !default;

/*边框颜色*/
$color-border: mix(#000, $gray1, 5%) !default;

/*字体颜色*/
$color-text: #333333 !default;
$color-text-minor: #999 !default;

// 颜色总合 组件内部用
$colorList: (
    g1:$gray1,
    g2:$gray2,
    g3:$gray3,
    g4:$gray4,
    g5:$gray5,
    g6:$gray6,
    g7:$gray7,
    g8:$gray8,
    g9:$gray9,
    w:#fff,
    primary:$color-primary,
    success:$color-success,
    warning:$color-warning,
    danger:$color-danger,
    info:$color-info,
    main:$color-main,
    second:$color-second,
);

$themeList: () !default;
@each $key, $value in $colorList {
  $selector: '&.#{$key}';
  /*@if ($key != 'normal') {
    $selector: '&.#{$key}';
  }*/

  $color: $color-text;
  @if ($key == 'main'){$color: #fff;}
  @if (lightness($value) <80) {$color: #fff;}
  $themeList: map-merge($themeList, (#{$selector}:(bgc:$value, color:$color)))
}


$mini: 0.5 !default;
$small: 0.75 !default;
$little: 0.85 !default;
$normal: 1 !default;
$big: 1.25 !default;
$more: 1.5 !default;
$super: 1.75 !default;

$sizeList: (
    mini:$mini,
    small:$small,
    little:$little,
    normal:$normal,
    big:$big,
    more:$more,
    super:$super,
);

$sizeList_:()!default;
@each $key, $value in $sizeList {
  $selector: '&.#{$key}';
  @if ($key == 'normal') {
    $selector: '&';
  }
  $sizeList_: map-merge($sizeList_, (#{$selector}:$value))
}
