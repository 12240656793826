@import "assets/style/_index.scss";

























































































.list {
    > .item {
        /*overflow: hidden;*/
    }

    &.border {
        > .item {
            + .item {
                border-top: 1px solid $color-border;
            }
        }
    }
    &.margin {
        > .item {
            //margin: $margin 0;
            + .item {
                margin-top: $margin;
            }
        }
    }
    &.padding {
        > .item {
            padding: 0 $padding;
        }
        &.all {
            > .item {
                padding: $padding;
            }
        }
    }
    &.full {
        &,
        > .inner {
            > .item {
                background-color: $color-component-bgc;
            }
        }
    }

    &.column {
        overflow: hidden;
        > .inner {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: stretch;
            flex-wrap: wrap;
            margin: -$margin-small;

            > .item {
                flex: 0 0 auto;
                padding: $margin-small;
            }
        }

        &.padding {
            > .inner {
                padding: 0 $padding;
            }
            &.all {
                > .inner {
                    padding: $padding;
                }
            }
        }
    }
}
