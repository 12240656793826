@import "assets/style/_index.scss";























































.ad-cube {
    @include clearfix;
    background-color: #fff;
    margin-bottom: 0;

    > .item {
        float: left;
        display: inline-block;
        width: 50%;

        > .inner {
            width: 100%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            height: 0;
            padding-top: 50%;
        }

        &.big {
            > .inner {
                padding-top: 100%;
            }
        }
        &.one {
            width: 100%;
            > .inner {
                padding-top: 100%;
            }
        }
    }
    &.border {
        margin-left: -1px;
        margin-right: -1px;
        > .item {
            border: 1px solid $color-border;
        }
    }
    &.reverse {
        > .item {
            float: right;
        }
    }
}
