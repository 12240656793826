@import "assets/style/_index.scss";






























.collapse {
    .collapse-item {
        + .collapse-item {
            margin-top: $margin;
        }
    }
    &.border {
        .collapse-item {
            + .collapse-item {
                margin-top: 0;
                border-top: 1px solid $color-border;
            }
        }
    }
}
