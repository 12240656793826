@import "assets/style/_index.scss";








































































































































.company-picker {
    flex: 1;
    > .out-show {
        > .inner {
            line-height: $formItemHeight;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            > .label {
                flex: 1;
            }
            > .clear {
                margin: 0 $margin-small;
            }
        }
        &.noop {
            > .inner {
                > .label {
                    color: $gray6;
                }
            }
        }
    }
}
