@import "assets/style/_index.scss";





























































































$size: 40px !default;
.checkbox {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > .input {
        position: relative;
        width: $size;
        height: $size;
        > .x-icon {
            font-size: $size;
        }
        > input {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.5;
            appearance: none;
            outline: none;
        }
    }
    > label {
        margin-left: $margin-small;
    }
    &.reverse {
        > .input {
            order: 1;
        }
        > label {
            margin-left: 0;
            margin-right: $margin-small;
        }
    }
    &.disabled {
        opacity: 0.5;
    }
}
