@import "assets/style/_index.scss";




































































































































.collapse-item {
    > .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: $padding;
        background-color: #fff;
        min-height: $navHeight;

        > .inner {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            > .extra,
            > .badge,
            > .x-icon {
                flex: 0 0 auto;
                margin-left: $margin-small;
            }

            > .x-label {
                flex: 1 1 1%;
                //min-height: $formItemHeight;
            }

            > .extra {
                color: $color-text-minor;
            }
        }
        > .link {
            margin-left: $margin-small;
        }
    }

    > .body {
        //padding: $padding;
        border-top: 1px solid $color-border;
        background-color: $gray1;
    }

    &.focus-extra {
        > .header {
            > .inner {
                > .x-label {
                    flex: 0 0 auto;
                }

                > .extra {
                    flex: 1 1 1%;
                    text-align: right;
                }
            }
        }
    }

    &.column {
        > .header {
            > .inner {
                flex-direction: column;
                align-items: stretch;
                > .extra {
                    margin-left: 0;
                    margin-top: $margin-small;
                }
            }
        }
    }
}
